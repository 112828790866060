// @flow
import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import Image from '../../components/image';
import GlossaryLink from '../../components/glossary-link';
import Link from '../../components/link';
import type { FrontMatter, ImageType } from '../../utils/types';
import { withFrontMatter } from '../../components';
import AllImagesLink from '../../components/all-images-link';
import CanyonRatingCard from '../../components/canyon-rating-card';

const NerdyTechnicalStuff = styled.p`
  text-align: center;
`;

type Props = {
  frontMatter: FrontMatter,
  data: {
    _0J2A6221: ImageType,
    _0J2A6240: ImageType,
    _0J2A6338: ImageType,
    _0J2A6506: ImageType,
    _0J2A6521: ImageType,
    _0J2A6547: ImageType,
    _P5180733: ImageType,
    _P5180734: ImageType,
    _P5180735: ImageType,
    _P5180738: ImageType,
    _P5180741: ImageType,
    _P5180746: ImageType,
    _P5180747: ImageType,
    _P5180763: ImageType,
  },
};

class Blog_2019_05_18_Rappel_Maui extends React.PureComponent<Props> {
  render() {
    const { data, frontMatter } = this.props;

    return (
      <Layout frontMatter={frontMatter} toc>
        <CanyonRatingCard rating="3CI" title="Rappel Maui Canyon" />
        <p>
          Tickets go crazy cheap for Maui for like an hour one morning,
          Savannah, my wife, is one of those people who's always on top of
          everything so she gets tickets, and I somehow convince her to do a
          guided canyoneering trip with{' '}
          <Link to="https://www.rappelmaui.com/">Rappel Maui</Link>. This was
          her first technical canyon descent, by the way, and I couldn't be
          prouder of how she handled herself and couldn't imagine a more
          beautiful setting.
        </p>
        <p>
          I had a couple of goals for this trip. First and foremost I wanted
          Savannah to actually enjoy the experience and <em>hopefully</em> want
          to go again. Second, I wanted to rappel a waterfall in Maui! How cool
          is that?!? Lastly, I needed to know the veracity of{' '}
          <Link to="http://www.math.utah.edu/~sfolias/canyontales/tale/?i=shtheaps">
            the story of Bimbo the Heaps-veteran chihuahua
          </Link>
          . ⟵ Read that story if you haven't already. More on that right now.
        </p>
        <p>
          We arrived at our designated meeting spot with our 2 guides, Anne and
          Elena, and 5 other clients and headed out on the Road to Hana.
        </p>
        <p>
          On the way I recounted the story of Rappel Maui owner Dave Black and
          his shitty trip through Heaps where he performed life-saving CPR on a
          suffocated chihuahua. So was it real or legend? One of the guides
          confirmed that yes, the story is accurate. They added that if you know
          Dave Black and <em>how he is</em> then the story is completely
          believable. The guide also revealed the fate of poor Bimbo: a few
          years later he was bitten by a rattlesnake and died.
        </p>
        <p>
          Anyways, back to the canyon at-hand. The canyon that Rappel Maui
          guides is in the wettest, jungliest part of the island. It's at the
          Garden of Eden about 1/3 of the way on the Road to Hana. Once there we
          met our photographer Mary and got our gear.
        </p>
        <h2>Gearing up</h2>
        <NerdyTechnicalStuff>
          <Link to="#rappel-training">Skip the nerdy technical stuff</Link>
        </NerdyTechnicalStuff>
        <p>
          This is the first time I have been professionally guided through a
          canyon. Many of my descriptions will be me recalling what the guides
          did. On that note, everything they did was safe and it was clear they
          had done these things many many times before.
        </p>
        <p>
          The guides had Eldrid canyon harnesses with a{' '}
          <GlossaryLink>butt protector</GlossaryLink>. The guests had{' '}
          <GlossaryLink id="rappelling-harness">
            rappelling harnesses
          </GlossaryLink>{' '}
          with no butt protector, and one <GlossaryLink>gear loop</GlossaryLink>{' '}
          on each side. Can't remember the brand. Most things were Cypher and
          Eldrid.
        </p>
        <p>
          We also had a <GlossaryLink>sling</GlossaryLink> for use as a{' '}
          <GlossaryLink>safety tether</GlossaryLink>. If I remember right it was
          24" <GlossaryLink>nylon</GlossaryLink>.
        </p>
        <p>
          The <GlossaryLink>rappel device</GlossaryLink> was a Kong Oka. Never
          used one before. It's like a Petzl Piranha for setting initial
          friction (as in it will probably pop off the micro horns as soon as it
          is unweighted). It has bigger horns for adding friction mid-rappel,
          though. This is not a review, by the way. I only used it on 3 rappels.
          If I use it for at least a year I will review it. It looked fairly new
          - probably has never been through Imlay or any other{' '}
          <GlossaryLink>Colorado Plateau</GlossaryLink>
          canyon.
        </p>
        <p>
          The <GlossaryLink>carabiner</GlossaryLink> attached to my Oka was a
          Cypher brand, medium size, aluminum,{' '}
          <GlossaryLink id="hms-carabiner">HMS</GlossaryLink>, concave beam,
          keylock, screw gate, and inline hinge. The carabiner attached to my
          safety tether was similar but I don't remember the brand. Both of
          their springs were worn to the point where they didn't close very
          well. The guides did the screwing and unscrewing for most people so I
          don't think anyone else would have noticed. Despite the wear on the
          springs, the biners looked much newer than all of my carabiners. I
          trusted my life on them without hesitation.
        </p>
        <p>
          They provided felt-soled shoes for us. These were new to me and I was
          excited to try them out. The felt felt like one of those green
          scouring pad you use to scrub dishes. It was about half and inch
          thick. The rest of the shoe felt like a 5 mm{' '}
          <GlossaryLink>neoprene</GlossaryLink> sock bit with a zipper on the
          side. The shoes worked well on the lava rock but pretty poorly in
          other places such as the metal/plastic edges on some of the steps. I
          didn't bring any stealth or Vibram rubber shoes so I don't know how
          they would have compared. I did bring some La Sportiva hiking shoes
          and they struggled one some of the smooth rocks on some of the hikes.
        </p>
        <Image image={data._P5180733} caption="felt-soled canyoneering shoes" />
        <p>
          Can't remember the brand of helmets but they were standard climbing
          helmets with chin strap and twist-tightener in the back.
        </p>
        <p>
          We didn't use gloves which was perfectly fine for this short canyon.
          They also had Imlay Leprechaun packs and kegs if we wanted to use
          them.
        </p>
        <p>
          The guides had a bunch of other gear on them as well. Handled
          ascenders, VT Prusiks, cordage, slings, a bunch of extra biners, etc.
          Anne had on a <GlossaryLink>full wetsuit</GlossaryLink> and Elena
          didn't. I thought that was weird.
        </p>
        <p>
          They laid out our harnesses with leg loops waist loops opened so it
          would be easy to step in and put them on without accidentally getting
          them twisted or on put on incorrectly. The rappel device was attached
          to the belay loop as was the safety tether. Once we had our shoes,
          helmets, and harnesses on they had us clip our safety tether to our
          non-rappel side gear loop. You know, the normal thing canyoneers do.
          The rappel device dangled the entire canyon.
        </p>
        <Image
          image={data._P5180734}
          caption="Savannah posing with the shoes and the gear laid out on the ground for us"
        />
        <Image
          image={data._P5180735}
          caption="Teaching us the purpose of each piece the gear"
        />
        <p>We were all set and headed off to the canyon.</p>
        <h2>Rappel Training</h2>
        <p>
          The <GlossaryLink>approach</GlossaryLink> was a couple hundred feet
          long and with like 50 feet of elevation loss. We came out of the
          forest onto a spur with one 30 feet practice rappel into the drainage.
        </p>
        <Image image={data._P5180738} caption="Canyon approach" />
        <Image image={data._P5180741} caption="Canyon approach" />
        <NerdyTechnicalStuff>
          <Link to="#second-rappel">Skip the nerdy technical stuff</Link>
        </NerdyTechnicalStuff>
        <p>
          The <GlossaryLink>anchor</GlossaryLink> a trio of what looked like
          rebar bights set in concrete. I wasn't exactly sure but they looked
          way more bomber than glue-ins. The rigging was{' '}
          <GlossaryLink>kernmantle rope</GlossaryLink> attached to each anchor
          with a <GlossaryLink>figure 8 on a bight</GlossaryLink>. The{' '}
          <GlossaryLink>master point</GlossaryLink> was probably a triple figure
          8 on a bight or something equally ridiculously strong. Also, the rope
          was entirely inside some <GlossaryLink>tubular webbing</GlossaryLink>.
          It looked weird seeing tubular webbing with a figure 8 rather than a
          <GlossaryLink>ring bend</GlossaryLink> and I had to remind myself that
          the webbing was just a second <GlossaryLink>sheath</GlossaryLink> to
          protect the rope.
        </p>
        <p>
          Elena had the rope in her backpack that functioned as the{' '}
          <GlossaryLink>rope bag</GlossaryLink>. The rope was Sterling Canyon
          Tech, 9.5 mm and <GlossaryLink>Technora</GlossaryLink> sheath. It was
          a surprise that they used it considering how bouncy it is when wet.
          But I don't know of any other Technora sheath rope that is thick
          enough to guide on.
        </p>
        <p>
          Elena secured the rope onto the the anchor with a{' '}
          <GlossaryLink>Munter Mule Overhand</GlossaryLink>. She also attached
          the <GlossaryLink>bag end</GlossaryLink> to a carabiner via a{' '}
          <GlossaryLink>clove hitch</GlossaryLink> and then clipped the biner to
          the <GlossaryLink>master point</GlossaryLink>. I incorrectly assumed
          it was another backup for the MMO. She said it was in case they need
          to have a second rappel line (for something like a pick off) it is all
          ready to go.
        </p>
        <p>It was here than Anne showed us all how to rappel.</p>
        <p>
          She showed us holding onto the rope behind our back and compared that
          to a stopped car. Then she showed us slowly bringing it out away from
          our body (a tricep press) was like pressing the gas petal on your car.
        </p>
        <Image
          image={data._P5180746}
          caption="Teaching us how to apply the brakes while on rappel"
        />
        <p>
          She then went through foot and leg positioning and showed what
          happened when you moved your feet down too far without lowering the
          rest of your body as well as lowering your body without moving your
          feet down. Lots of stuff that I no longer think about but a good
          reminder that rappelling still takes some practice to get right.
        </p>
        <Image
          image={data._P5180747}
          caption="What happens when you let out your brake without stepping down"
        />
        <p>
          After the demonstration she went down and provided a{' '}
          <GlossaryLink>bottom belay</GlossaryLink> for each guest as they went
          down. They didn't explain what it was, didn't have us do any
          signalling for being on belay, and she basically just had us on belay
          the whole time.
        </p>
        <p>
          As people were going down Elena and Mary would compliment everyone on
          their form and help build their confidence. They would also have each
          of us look up and smile at least once each rappel so they could take
          our pictures.
        </p>
        <Image
          image={data._0J2A6221}
          caption={
            <>
              Savannah on her first rappel - Photo by Mary at{' '}
              <Link to="https://www.rappelmaui.com/">Rappel Maui</Link>
            </>
          }
        />
        <h2>Second Rappel</h2>
        <p>
          Once all of us were down Elena came down and pulled the rope while Ann
          led into the drainage and across the the next rappel just a few feet
          away. This was the first rappel in flowing water and into a pool.
        </p>
        <Image
          image={data._P5180763}
          caption="On our way to the second and largest rappel"
        />
        <p>
          Looking around, I must have seen 20 different bolts. I figured there
          were some for when it was raining, some for when the water was too
          high, and some for a second or third or fourth group if needed.
        </p>
        <Image
          image={data._0J2A6240}
          caption={
            <>
              Top of the second rappel - Photo by Mary at{' '}
              <Link to="https://www.rappelmaui.com/">Rappel Maui</Link>
            </>
          }
        />
        <p>
          For this rappel, Anne was standing in the pool the entire time
          providing the bottom belay. I recall wondering why she was wearing a
          wetsuit but Elena wasn't. Now I knew. Elena was again up top but this
          time she has a strand of Sterling C-IV going to the edge. She attached
          herself to it with the <GlossaryLink>VT Prusik</GlossaryLink> and some{' '}
          <GlossaryLink>friction hitch</GlossaryLink>. I at first assumed it was
          a <GlossaryLink>Valdotain Tresse</GlossaryLink> but it might have been
          an <GlossaryLink>autoblock</GlossaryLink>. Doesn't matter. She would
          walk the guest out to the edge and talk them through the additional
          challenges they would encounter at this rappel.
        </p>
        <p>
          I watched Savannah go down first. I couldn't hear what Elena was
          saying to her at the edge, but they were there for a while talking and
          eventually she made her way down and out of sight. It was then my turn
          and I went over the edge.
        </p>
        <Image
          image={data._0J2A6338}
          caption={
            <>
              Savannah on the second rappel - Photo by Mary at{' '}
              <Link to="https://www.rappelmaui.com/">Rappel Maui</Link>
            </>
          }
        />
        <p>
          The water was surprisingly warm. Well not warm, but not cold enough to
          think I would need a wetsuit. This was a very easy class C rappel.
          Flowing water enough to block my view of the rock so I couldn't see
          where I was stepping very well. But that was the only noticeable
          difference.
        </p>
        <p>
          Landing in the pool, I went to pull the rope through my rappel device.
          Anne told me that she needed extra rope to provide the belay and not
          to just unclip it. Savannah was waiting for me to come down and
          greeted me with a big smile. She told me it had been scary for her and
          she went down really slowly but was able to finish it without any
          difficulties. We swam over to the edge of the pool with the rest of
          the group.
        </p>
        <p>
          Elena and Mary rigged a line from a different set of bolts to bypass
          the pool - Mary had lots of expensive equipment that needed to stay
          dry. Interesting. Something I would never have considered doing unless
          I had been guiding for a long time.
        </p>
        <p>
          They then explained that we would be doing some boulder hopping and to
          be careful because this is where everyone got injured. Stuff like that
          makes me smile because it's true. The vast majority of injuries happen
          when we let our guard down. Turns out we only had a few feet of
          boulder hopping over to some stairs that parallelled the drainage and
          put us right near the third and final rappel.
        </p>
        <h2>Final Rappel</h2>
        <Image
          image={data._0J2A6506}
          caption={
            <>
              Final rappel - Photo by Mary at{' '}
              <Link to="https://www.rappelmaui.com/">Rappel Maui</Link>
            </>
          }
        />
        <p>
          I reminded Savannah that this rappel had nothing "new" - it was
          shorter than the second rappel and just as much water flow - so there
          was nothing to be nervous about. Again she got rigged and disappeared
          over the edge - this time much faster and more confidently, though.
        </p>
        <Image
          image={data._0J2A6521}
          caption={
            <>
              Savannah on the final rappel - Photo by Mary at{' '}
              <Link to="https://www.rappelmaui.com/">Rappel Maui</Link>
            </>
          }
        />
        <p>
          When it was my turn Elena handed me a bight of rope. I was facing down
          canyon and rigged backwards. Newbie mistake. I quickly rerigged and
          back up over the edge. All of these rappels had super solid edges.
          Lava rock is so different from sandstone and made me feel like a
          first-time canyoneer navigating the rappel. I kept expecting the rocks
          to crumble or chip as I weighted them but they never did.
        </p>
        <p>
          At the bottom of this rappel was a small ledge and we could optionally
          jump in. Anne said it was a 30 foot pool and it would have been a 3
          foot jump. I'm not really a jumper unless I have to so I down climbed
          and slipped into the pool.
        </p>
        <h2>Exit</h2>
        <Image
          image={data._0J2A6547}
          caption={
            <>
              Swim to the exit trail - Photo by Mary at{' '}
              <Link to="https://www.rappelmaui.com/">Rappel Maui</Link>
            </>
          }
        />
        <p>
          After all the guests were down Elena and Mary bypassed the final
          rappel and made their way back to the start. The rest of us stayed at
          the bottom for a bit and then followed. They said it was 200 feet of
          elevation gain to get back up to the top. That felt about right.
          Almost enough to start breathing heavy and then we were back.
        </p>
        <p>
          We took off all our wet gear and put it in tubs of water to soak -
          maybe disinfect the stuff? Kill the quagga mussels? Not sure. We then
          dried off, changed clothes, and had a delicious lunch. Turkey wrap,
          Maui gold pineapple, and cookies.
        </p>
        <p>
          We wandered around the area for a few minutes while the guides cleaned
          up and the drove back the way we came. Lots of "firsts" on this trip:
        </p>
        <h2>Firsts</h2>
        <ul>
          <li>First time canyoneering outside of Utah</li>
          <li>First time to be professionally guided through a canyon</li>
          <li>First time canyoneering with my wife</li>
          <li>First time using a Kong Oka</li>
          <li>First time to wear a life jacket in a canyon</li>
        </ul>
        <AllImagesLink id="2019-05-18-rappel-maui" />
        <h2>Update 6/19/2019</h2>
        <blockquote>
          I learned that shortly after our trip Rappel Maui replaced much of the
          gear we had used. The Oka rappel devices have been replaced with{' '}
          <Link to="https://www.edelrid.de/en/sports/descender/hannibal.html">
            Edelrid Hannibals
          </Link>
          . The screw gate carabiners have been replaced with auto lockers. The
          slings used as safety tethers have been replaced with what looks like{' '}
          <GlossaryLink>dynamic rope</GlossaryLink>.
        </blockquote>
      </Layout>
    );
  }
}

export default withFrontMatter('2019-05-18-rappel-maui')(
  Blog_2019_05_18_Rappel_Maui
);

export const query = graphql`
  query {
    _0J2A6221: file(
      relativePath: { eq: "images/blog/2019-05-18-rappel-maui/0J2A6221.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _0J2A6240: file(
      relativePath: { eq: "images/blog/2019-05-18-rappel-maui/0J2A6240.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _0J2A6338: file(
      relativePath: { eq: "images/blog/2019-05-18-rappel-maui/0J2A6338.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _0J2A6506: file(
      relativePath: { eq: "images/blog/2019-05-18-rappel-maui/0J2A6506.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _0J2A6521: file(
      relativePath: { eq: "images/blog/2019-05-18-rappel-maui/0J2A6521.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _0J2A6547: file(
      relativePath: { eq: "images/blog/2019-05-18-rappel-maui/0J2A6547.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _P5180733: file(
      relativePath: { eq: "images/blog/2019-05-18-rappel-maui/P5180733.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _P5180734: file(
      relativePath: { eq: "images/blog/2019-05-18-rappel-maui/P5180734.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _P5180735: file(
      relativePath: { eq: "images/blog/2019-05-18-rappel-maui/P5180735.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _P5180738: file(
      relativePath: { eq: "images/blog/2019-05-18-rappel-maui/P5180738.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _P5180741: file(
      relativePath: { eq: "images/blog/2019-05-18-rappel-maui/P5180741.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _P5180746: file(
      relativePath: { eq: "images/blog/2019-05-18-rappel-maui/P5180746.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _P5180747: file(
      relativePath: { eq: "images/blog/2019-05-18-rappel-maui/P5180747.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _P5180763: file(
      relativePath: { eq: "images/blog/2019-05-18-rappel-maui/P5180763.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
